// @ts-check

import _ from 'lodash'

export const getStartYearDate = (year = new Date().getFullYear()) =>
  new Date(year, 0, 1, 0, 0, 0).toUTCString()

export const getEndYearDate = (year = new Date().getFullYear()) =>
  new Date(year, 11, 31, 23, 59, 59).toUTCString()

export const getMonthsNamesArray = (locale = 'SV') => {
  return Array.from({ length: 12 }, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString(locale, {
      month: 'long'
    })
  })
}

export const getDataForChart = (improvements, numOfEmployees) => {
  const itemsPerMonth = new Array(12).fill(0)

  improvements.forEach(item => {
    const month = new Date(item.statusChangedOn).getMonth()

    itemsPerMonth[month] += 1
  })

  const accumulatedItemsPerMonth = []

  itemsPerMonth.reduce(function (acc, next, idx) {
    return (accumulatedItemsPerMonth[idx] = acc + next)
  }, 0)

  const accumulatedItemsPerMonthPerEmployee = accumulatedItemsPerMonth.map(
    countPerMonth => countPerMonth / numOfEmployees
  )

  return accumulatedItemsPerMonthPerEmployee
}

export const getDataForTeamsTable = (improvements, teams) => {
  const teamsObj = teams.reduce(
    (acc, next) => ({
      ...acc,
      [next.id]: {
        id: next.id,
        name: next.title,
        implemented: 0,
        interrupted: 0
      }
    }),
    {}
  )

  const teamsData = improvements.reduce((acc, next) => {
    const getImplementedCount = () => {
      let currentCount = acc[next.challenge.id]?.implemented ?? 0

      return next.ideaState === 'COMPLETED' ? (currentCount += 1) : currentCount
    }

    const getInterruptedCount = () => {
      let currentCount = acc[next.challenge.id]?.interrupted ?? 0

      return next.ideaState === 'INTERRUPTED'
        ? (currentCount += 1)
        : currentCount
    }

    return {
      ...acc,
      [next.challenge.id]: {
        ...acc[next.challenge.id],
        implemented: getImplementedCount(),
        interrupted: getInterruptedCount()
      }
    }
  }, teamsObj)

  return Object.values(teamsData)
}

/**
 * Generates a hierarchical path array from a given OLC name.
 * @param {string} olcName - The OLC code with an optional sub-part, e.g., "DC-SP".
 * @returns {string[]} - Array of path segments, e.g., ["DC", "DC-S", "DC-SP"].
 */
export const getDepartementStructurePath = olcName => {
  const [base, sub] = olcName.split('-')

  const path = [base]

  if (!!sub) {
    // Loop through each character in the sub-part (excluding the last character)
    for (let i = 1; i < sub.length; i++) {
      path.push(`${base}-${sub.slice(0, i)}`)
    }

    // Add the full OLC name to the path
    path.push(olcName)
  }

  return path
}

export const getStructuredDepartments = items => {
  const itemsWithPath = items.map(item => ({
    ...item,
    paths: getDepartementStructurePath(item.name)
  }))

  const obj = {}

  itemsWithPath.forEach(dep => {
    const path = dep.paths.join('.children.')
    _.set(obj, path, dep)
  })

  // update empty objects (e.g. parent department that has been created by set(obj, path...))
  const updateEmptyObjValues = o => {
    const objCopy = { ...o }

    Object.keys(objCopy).forEach(key => {
      if (!objCopy[key].id) {
        objCopy[key].id = key
        objCopy[key].name = objCopy[key].name || key
        objCopy[key].implemented = objCopy[key].implemented || 0
        objCopy[key].interrupted = objCopy[key].interrupted || 0
        objCopy[key].paths = getDepartementStructurePath(objCopy[key].name)
      }

      if (!!objCopy[key].children) {
        updateEmptyObjValues(objCopy[key].children)
      }
    })

    return objCopy
  }

  const updatedObj = getChildrensSums(updateEmptyObjValues(obj))

  return Object.values(updatedObj)
}

export const getChildrensSums = olcs => {
  const copy = _.cloneDeep(olcs)

  const updateSums = obj => {
    Object.values(obj).forEach(olc => {
      if (olc.children) {
        olc.childrenImplemented = olc.childrenImplemented || 0
        olc.childrenInterrupted = olc.childrenInterrupted || 0
        updateSums(olc.children)
      }

      olc.paths.slice(0, -1).forEach((path, idx, paths) => {
        const pathWithChildren = paths.slice(0, idx + 1).join('.children.')

        _.get(copy, pathWithChildren).childrenImplemented += olc.implemented
        _.get(copy, pathWithChildren).childrenInterrupted += olc.interrupted
      })
    })
  }

  updateSums(copy)

  return copy
}
